<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <EditHeader
      v-sticky
      style="padding-top: 16px; background: white"
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: prodForm.status }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit('prodForm')"
      @refresh="buttonRefresh"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button
          type="success"
          size="small"
          @click="generateFPPC()"
          v-if="prodForm.prod_mainsup === '0' && prodForm.prod_attr === '2' && isShow && prodForm.status === 2"
        >
          生成成品采购合同</el-button
        >
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(prodForm.status).type">{{ helper.getStatusName(prodForm.status).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <el-form ref="prodForm" :model="prodForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-row :gutter="20" class="vg_mb_5">
          <el-col :span="24">
            <h3 style="color: #606266" class="vg_mb_5">基本信息</h3>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-form-item label="属性" prop="prod_attr">
              <el-radio @change="prodAttrChange" v-model="prodForm.prod_attr" label="1" :disabled="!isCopy">商品 </el-radio>
              <el-radio @change="prodAttrChange" v-model="prodForm.prod_attr" label="2" :disabled="!isCopy">部件 </el-radio>
              <!--<el-radio @change="prodAttrChange" v-model="prodForm.prod_attr" label="3" :disabled="!isCopy">商品既部件</el-radio>-->
            </el-form-item>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8" v-if="prodForm.prod_attr === '1'">
              <el-form-item label="是否是套装" prop="is_suit">
                <el-radio-group v-model="prodForm.is_suit" @input="val => (val === '1' ? (prodForm.prod_no = 'ZS') : '')">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="prodForm.prod_attr !== '2'">
              <el-form-item label="种类" prop="prod_type">
                <el-radio-group v-model="prodForm.prod_type">
                  <el-radio :label="1">Classic</el-radio>
                  <el-radio :label="2">SuperChewer</el-radio>
                  <el-radio :label="3">Home</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="prodForm.prod_attr !== '1'">
              <el-form-item
                label="部件名称"
                prop="prod_partno"
                :rules="prodForm.prod_attr !== '1' ? [{ required: true, message: ' ' }] : [{ required: false, message: ' ' }]"
              >
                <el-input
                  maxlength="20"
                  :disabled="prodForm.prod_attr === '1'"
                  @input="prodForm.prod_partno = helper.keepSpace2(prodForm.prod_partno)"
                  v-model="prodForm.prod_partno"
                  placeholder="请填写部件名称"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8">
              <el-form-item label="中文名称" prop="prod_name">
                <el-input maxlength="100" v-model="prodForm.prod_name" placeholder="请填写中文名称" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="英文名称" prop="prod_ename">
                <el-input maxlength="100" v-model="prodForm.prod_ename" placeholder="请填写英文名称" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户简称" prop="cust_id">
                <el-select v-model="prodForm.cust_id" clearable filterable size="small">
                  <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
                </el-select>
                <!--                <el-input disabled v-model="prodForm.cust_abbr" placeholder="请填写客户简称" show-word-limit>-->
                <!--                  <template slot="append">-->
                <!--                    <el-link type="primary" class="vg_cursor_hander" @click="dialogVisible3 = true" :disabled="isShow"> 选择 </el-link>-->
                <!--                  </template>-->
                <!--                </el-input>-->
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8">
              <el-form-item label="我司货号" prop="prod_no">
                <el-input
                  v-model="prodForm.prod_no"
                  placeholder="请填写我司货号"
                  show-word-limit
                  @input="prodForm.prod_no = keepLetter2AndNumber5(prodForm.prod_no)"
                  :maxlength="prodNoMaxLength"
                  :disabled="prodNoDisabled"
                >
                  <template slot="append">
                    <el-link type="primary" class="vg_cursor_hander" v-if="ifProdAttr" @click="importPordNo" :disabled="isShow">选择 </el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="货号后缀" prop="prod_poststfix">
                <el-input
                  maxlength="10"
                  v-model="prodForm.prod_poststfix"
                  @input="prodForm.prod_poststfix = helper.keepEngNum(prodForm.prod_poststfix)"
                  placeholder="请填写货号后缀"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户货号" prop="prod_cust_no">
                <el-input
                  maxlength="20"
                  v-model="prodForm.prod_cust_no"
                  @input="prodForm.prod_cust_no = helper.keepSpace2(prodForm.prod_cust_no)"
                  placeholder="请填写客户货号"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="销售价" prop="prod_usd">
                <el-input
                  maxlength="9"
                  v-model="prodForm.prod_usd"
                  @input="val => (prodForm.prod_usd = keep4Decimal(val))"
                  placeholder="请填写销售价"
                  show-word-limit
                >
                  <template slot="append">{{ prodForm.cust_currency === 'RMB' ? '元' : '美元' }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="折扣价" prop="prod_dusd">
                <el-input
                  maxlength="9"
                  v-model="prodForm.prod_dusd"
                  @input="prodForm.prod_dusd = helper.keepTNum1(prodForm.prod_dusd)"
                  @blur="prodForm.prod_dusd = helper.calcPrice(prodForm.prod_dusd, 4, 10000)"
                  placeholder="请填写折扣价"
                  show-word-limit
                >
                  <template slot="append">{{ prodForm.cust_currency === 'RMB' ? '元' : '美元' }}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isBussinessDept">
              <el-form-item label="美金报价" prop="prod_usd_price">
                <el-input
                  v-model="prodForm.prod_usd_price"
                  @input="prodForm.prod_usd_price = helper.keepTNum1(prodForm.prod_usd_price)"
                  @blur="prodForm.prod_usd_price = helper.calcPrice(prodForm.prod_usd_price, 4, 10000)"
                  placeholder="请填写美金报价"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '2'">
            <!--            <el-col :span="8" v-if="isBussinessDept5">-->
            <!--              <el-form-item label="导入Qarma" prop="qarma">-->
            <!--                <el-radio-group v-model="prodForm.qarma">-->
            <!--                  <el-radio :label="1">是</el-radio>-->
            <!--                  <el-radio :label="0">否</el-radio>-->
            <!--                </el-radio-group>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            2024-1-31 16:16:16 取消销售经办人-->
            <!--            <el-col :md="8">-->
            <!--              <el-form-item label="销售经办人" prop="scon_stff_id">-->
            <!--                <el-select v-model="prodForm.scon_stff_id" placeholder="请选择销售经办人" size="small" clearable filterable>-->
            <!--                  <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>-->
            <!--                </el-select>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <el-col :span="8">
              <el-col :span="12">
                <el-form-item label="单位" prop="prod_unit" label-width="110px">
                  <el-select filterable v-model="prodForm.prod_unit" placeholder="请选择单位">
                    <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="币种" prop="cust_currency" :rules="[{ required: prodForm.prod_attr !== '2', trigger: ['blur', 'change'], message: ' ' }]">
                  <el-select filterable v-model="prodForm.cust_currency" placeholder="请选择销售币种">
                    <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :md="8">
              <el-form-item label="老ERP货号" prop="rich_prod_no">
                <el-input v-model="prodForm.rich_prod_no" maxlength="50" placeholder="暂无" show-word-limit />
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :md="8">
              <el-form-item :label="prodForm.prod_attr === '1' || prodForm.prod_attr === '3' ? '商品版本号' : '部件版本号'" prop="prod_version_no">
                <el-input v-model="prodForm.prod_version_no" maxlength="5" placeholder="请填写版本号" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item :label="prodForm.prod_attr === '1' || prodForm.prod_attr === '3' ? '商品价格备注' : '部件价格备注'" prop="price_remarks">
                <el-input v-model="prodForm.price_remarks" placeholder="请填写" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" v-if="prodForm.prod_attr === '2'">
              <el-form-item label="单位" prop="prod_unit">
                <el-select filterable v-model="prodForm.prod_unit" placeholder="请选择单位">
                  <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <el-col :span="8" v-if="prodForm.prod_attr === '2'">
              <el-form-item label="币种" prop="cust_currency" :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]">
                <el-select filterable v-model="prodForm.cust_currency" placeholder="请选择销售币种">
                  <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]" label="客户品牌" prop="cust_brand">
                <el-select v-model="prodForm.cust_brand" filterable placeholder="请选择客户品牌(无数据请去客户信息维护)">
                  <el-option v-for="item in custBrandList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5 flexV">
              图片信息
              <!--<h6><span style="color: red">*至少有一张图片</span></h6>-->
            </h3>
          </el-col>
          <el-col :span="24">
            <el-col :span="8">
              <el-form-item label="商品工艺图" prop="imge_id">
                <div class="vd_dis">
                  <div>
                    <el-upload
                      class="upload-demo"
                      drag
                      :action="uploadUrl"
                      :data="dataBody"
                      :show-file-list="false"
                      :on-success="res => handleAvatarSuccess(res, 1)"
                      :before-upload="beforeAvatarUpload"
                      ref="uploadMutiple1"
                    >
                      <imgPopover v-if="prodForm.imge_id" :imgeUrl="prodForm.imge_url" :imgeUrlO="prodForm.imge_urlO"></imgPopover>
                      <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                    </el-upload>
                    <span>大小不能超过3MB</span>
                  </div>
                  <div id="preview" class="vd_flex_right" style="display: flex; flex-direction: column" @paste="e => handlePaste(e, 1)">
                    <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
                    <el-tooltip content="清除商品工艺图" effect="dark" placement="right" style="margin-left: auto">
                      <el-button icon="el-icon-delete" type="text" @click="clearImage(1)"></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商品签字样" prop="imge_commodity">
                <div class="vd_dis">
                  <div>
                    <el-upload
                      ref="uploadMutiple2"
                      class="upload-demo"
                      drag
                      :action="uploadUrl"
                      :data="dataBody"
                      :show-file-list="false"
                      :on-success="res => handleAvatarSuccess(res, 2)"
                      :before-upload="beforeAvatarUpload"
                    >
                      <imgPopover v-if="prodForm.imge_commodity" :imgeUrl="prodForm.imge_commodity_imge_url" :imgeUrlO="prodForm.imge_commodity_imge_urlO">
                      </imgPopover>
                      <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                    </el-upload>
                    <span>大小不能超过3MB</span>
                  </div>
                  <div id="preview" class="vd_flex_right" style="display: flex; flex-direction: column" @paste="e => handlePaste(e, 2)">
                    <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
                    <el-tooltip content="清除商品签字样" effect="dark" placement="right" style="margin-left: auto">
                      <el-button icon="el-icon-delete" type="text" @click="clearImage(2)"></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="样品图" prop="imge_craft">
                <div class="vd_dis">
                  <div>
                    <el-upload
                      class="upload-demo"
                      ref="craftImge"
                      drag
                      disabled
                      :action="uploadUrl"
                      :data="dataBody"
                      :show-file-list="false"
                      :on-success="res => handleAvatarSuccess(res, 3)"
                      :before-upload="beforeAvatarUpload"
                    >
                      <imgPopover
                        v-if="prodForm.imge_craft_imge_url"
                        :imgeUrl="prodForm.imge_craft_imge_url + '_m.jpg'"
                        :imgeUrlO="prodForm.imge_craft_imge_urlO + '_m.jpg'"
                      >
                      </imgPopover>
                      <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                    </el-upload>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5">详细信息</h3>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <!--<el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '1'">-->
            <!--  <el-button type="primary" size="small" @click="dialogVisible4 = true">导入文本信息</el-button>-->
            <!--</el-col>-->
            <el-col :span="8" class="vg_mb_5">
              <el-form-item :rules="[{ required: prodForm.prod_attr !== '2', message: ' ' }]" label="产品品类" prop="prod_category">
                <el-cascader
                  :props="{ emitPath: true }"
                  size="small"
                  v-model="prodForm.prod_category"
                  placeholder="请选择"
                  :options="prodCategoryOptions"
                  filterable
                  @change="cascaderChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :md="24">
              <el-form-item :label="prodForm.prod_attr === '2' ? '中文描述' : '英文规格'" prop="description">
                <el-input v-model="prodForm.description" type="textarea" :rows="3" show-word-limit placeholder="请填写描述"></el-input>
              </el-form-item>
              <el-link
                v-if="prodForm.prod_attr === '2'"
                :disabled="isShow"
                class="vg_pointer"
                style="position: relative; left: 52px; bottom: 50px"
                type="primary"
                @click="dialogVisible4 = true"
                >选择
              </el-link>
            </el-col>
            <!--<el-col :span="8" class="vg_mb_5">-->
            <!--  <el-form-item label="产品类别" prop="prod_category">-->
            <!--    <el-select filterable v-model="prodForm.prod_category" disabled placeholder="请选择" @change="category" size="small">-->
            <!--      <el-option v-for="item in ProdCategory" :key="item.param1" :label="item.param1" :value="item.param1"></el-option>-->
            <!--    </el-select>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_category === '商检类'">-->
            <!--  <el-form-item label="产品类型" prop="product_type">-->
            <!--    <el-radio v-model="prodForm.prodDetailed.product_type" @change="productTypeChange('餐垫')" label="餐垫"> 餐垫 </el-radio>-->
            <!--    <el-radio v-model="prodForm.prodDetailed.product_type" @change="productTypeChange('木制品')" label="木制品"-->
            <!--      >木制品-->
            <!--    </el-radio>-->
            <!--    <el-radio v-model="prodForm.prodDetailed.product_type" @change="productTypeChange('食品')" label="食品"> 食品 </el-radio>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--            <el-col :span="8" class="vg_mb_5" v-if="prodForm.prod_category === '商检类'">-->
            <!--              <el-form-item label="英文描述" prop="prod_espec">-->
            <!--                <el-input v-model="prodForm.prodDetailed.description" show-word-limit placeholder="请填写英文描述"></el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="8" class="vg_mb_5" v-if="prodForm.prod_category === '商检类'">-->
            <!--              <el-form-item label="价格组成" prop="prod_espec">-->
            <!--                <el-input v-model="prodForm.prodDetailed.price_composition" show-word-limit placeholder="请填写价格组成"></el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--<el-col :span="24" class="vg_mb_5">-->
            <!--  <DynamicForm-->
            <!--    v-bind="$attrs"-->
            <!--    :total-width="mainForm.totalWidth"-->
            <!--    :split="mainForm.split"-->
            <!--    :form-list="mainForm.formProperties"-->
            <!--    :labelWidth="'120px'"-->
            <!--    ref="dynamicForm"-->
            <!--    class="dynamicForm"-->
            <!--    :is-show="isShow"-->
            <!--  />-->
            <!--</el-col>-->
            <!--            <el-col :span="24" class="vg_mb_5">-->
            <!--              <el-form-item label="中文规格" prop="prod_spec">-->
            <!--                <el-input-->
            <!--                  maxlength="100"-->
            <!--                  v-model="prodForm.prod_spec"-->
            <!--                  type="textarea"-->
            <!--                  :rows="1"-->
            <!--                  show-word-limit-->
            <!--                  placeholder="请填写中文规格"-->
            <!--                >-->
            <!--                </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="24" class="vg_mb_5">-->
            <!--              <el-form-item label="英文规格" prop="prod_espec">-->
            <!--                <el-input v-model="prodForm.prod_espec" type="textarea" :rows="1" show-word-limit placeholder="请填写英文规格"> </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <!--            <el-col :span="24" class="vg_mb_5">-->
            <!--              <el-form-item label="产品描述" prop="prod_desc">-->
            <!--                <el-input v-model="prodForm.prod_desc" type="textarea" :rows="2" show-word-limit placeholder="请填写产品描述"> </el-input>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
          </el-col>

          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5" v-if="prodForm.prod_sales === 1">发票信息</h3>
            <h3 style="color: #606266" class="vg_mb_5" v-if="prodForm.prod_sales === 2">报关信息</h3>
          </el-col>
          <el-col :span="24">
            <prodCustom v-if="prodForm.prod_sales" ref="prodCustom" :isShow="isShow" :prodForm="prodForm"></prodCustom>
          </el-col>
          <el-col :span="24" class="vg_mb_5">
            <h3 style="color: #606266" class="vg_mb_5">包装信息</h3>
          </el-col>
          <el-col :span="24">
            <prodSpec :prodForm="prodForm"></prodSpec>
          </el-col>
        </el-row>
        <!--            <el-col :span="12">
              <el-form-item label="更改号" prop="prod_suffix">
                <el-input
                  maxlength="2"
                  v-model="prodForm.prod_suffix"
                  @input="prodForm.prod_suffix = helper.keepTNum(prodForm.prod_suffix)"
                  placeholder="请填写更改号"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>-->
        <el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr !== '1'">
          <h3 style="color: #606266" class="vg_mb_5">供应商信息</h3>
          <el-col :span="24">
            <prodPartsInfo :prodForm="prodForm" :isShow="isShow"></prodPartsInfo>
          </el-col>
        </el-col>
        <el-col :span="24" class="vg_mb_5">
          <h3 style="color: #606266" class="vg_mb_5">其他信息</h3>
          <el-col :span="24" class="vg_mb_5">
            <el-form-item label="是否委外">
              <el-radio-group v-model="prodForm.prod_out">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否商检">
              <el-radio-group v-model="prodForm.prod_inspection">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="24" class="vg_mb_5" v-if="prodForm.prod_attr && prodForm.prod_attr !== '2'">
          <div v-if="prodForm.is_suit === '1'">
            <h3 style="color: #606266" class="vg_mb_5">关联信息</h3>
            <el-col :span="24" class="vg_mb_5">
              <ProdAssociated ref="prodAssociated"></ProdAssociated>
            </el-col>
          </div>
          <div>
            <h3 style="color: #606266" class="vg_mb_5">部件信息</h3>
            <el-col :span="24" class="vg_mb_5">
              <ProdPartsList
                ref="prodPartsList"
                :prodForm="prodForm"
                :isShow="isShow"
                @addProdPartList="
                  prodForm.prod_part_list.push({
                    prod_no: prodForm.prod_no,
                    prod_cust_no: prodForm.prod_cust_no || '',
                    prod_price: '',
                    prod_spec: '',
                    prod_use: '',
                    prod_partno: '',
                    prod_poststfix: '',
                    supplementInfo: { prod_hscode: '', prod_spec: '', prod_sales: prodForm.prod_sales },
                    prod_category: prodForm.prod_category.toString(),
                    prodDetailed: prodForm.prodDetailed,
                    submit: true,
                    prod_sales: prodForm.prod_sales,
                    prod_unit: '个'
                  })
                "
                :ProdCategory="this.ProdCategory"
              />
            </el-col>
          </div>
          <!--          <h3 style="color: #606266" class="vg_mb_5">材料信息</h3>-->
          <el-col :span="24" class="vg_mb_5">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="材料信息" name="first" :key="'first'">
                <ProdMasc ref="ProdMasc" :isShow="isShow" :prodForm="prodForm"></ProdMasc>
              </el-tab-pane>
              <el-tab-pane label="猫草信息" name="second" :key="'second'">
                <ProdCatnipList ref="ProdCatnipList" :prodForm="prodForm" :prodUnitList="prodUnitList" :isShow="isShow" />
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <!--    <el-dialog title="委托打样单" :visible.sync="dialogVisible1" width="70%">-->
    <!--      <ProdRequList @changePordNo="changePordNo"></ProdRequList>-->
    <!--    </el-dialog>-->
    <el-dialog title="产品设计单" :visible.sync="dialogVisible1" width="70%">
      <ProdDesiList @changePordNo="changePordNo"></ProdDesiList>
    </el-dialog>
    <el-dialog title="询价单" :visible.sync="dialogVisible2" width="70%">
      <ProdRqutList @changePordNo="changePordNo"></ProdRqutList>
    </el-dialog>
    <el-dialog title="客户信息" :visible.sync="dialogVisible3" width="70%">
      <ProdCustList @changeCustAbbr="changeCustAbbr"></ProdCustList>
    </el-dialog>
    <el-dialog title="导入文本信息" :visible.sync="dialogVisible4" width="70%">
      <ClauList @inputClau="inputClau"></ClauList>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { prodAPI } from '@api/modules/prod';
import { imgeAPI } from '@api/modules/imge';
import { optnAPI } from '@api/modules/optn';
import EditHeader from '@/views/component/editHeaderLine';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import imgPopover from '@/views/component/imgPopover';
import prodCustom from './Componet/ProdCustom.vue';
import prodSpec from './Componet/ProdSpec.vue';
import prodPartsInfo from './Componet/ProdPartsInfo.vue';
import ProdPartsList from './Componet/ProdPartsList.vue';
import ProdMasc from './Componet/ProdMasc.vue';
import ProdCatnipList from './Componet/ProdCatnipList.vue';
import ProdRequList from './Componet/ProdRequList.vue';
import ProdRqutList from './Componet/ProdRqutList.vue';
import ProdCustList from './Componet/ProdCustList.vue';
import { base64ToFile, imageToBase64 } from '@/assets/js/imgCopy.js';
import ProdAssociated from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdAssociated.vue';
import UrlEncode from '@assets/js/UrlEncode';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { keep4Decimal } from '@assets/js/regExUtil';
import { stffAPI } from '@api/modules/staff';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { plushToys } from '@/views/MessageManagement/ProdManage/TabChild/ProdDetails';
import ProdDesiList from '@/views/MessageManagement/ProdManage/TabChild/Componet/ProdDesiList.vue';
import { getDataCenterByPermId, getDept } from '@api/public';
import { getArrayIds } from '@assets/js/arrayUtils';
import ClauList from '@/views/MessageManagement/ProdManage/TabChild/Componet/ClauList.vue';
import SconCatnipList from '@/views/SalesManagement/SconManage/TabChild/Component/SconCatnipList.vue';
import { custAPI } from '@api/modules/cust';

export default {
  name: 'prodEditMain',
  components: {
    SconCatnipList,
    ClauList,
    ProdDesiList,
    DynamicForm,
    ProdAssociated,
    EditHeader,
    inputUser,
    imgPopover,
    prodCustom,
    prodSpec,
    prodPartsInfo,
    ProdPartsList,
    ProdMasc,
    ProdCatnipList,
    ProdRequList,
    ProdRqutList,
    ProdCustList
  },
  data() {
    return {
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(plushToys)
      },
      ProdCategory: [],
      prodCategoryOptions: [],
      isCopy: false,
      ifProdAttr: true,
      activeName: 'first',
      rules: {
        prod_attr: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_no: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_name: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_ename: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_mainsup: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_cust_no: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_id: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_brand: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_unit: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_hscode: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_use: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        prod_usd: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        is_suit: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_ilk: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_id: [{ required: true, trigger: 'blur', message: ' ' }],
        prod_price: [{ required: true, trigger: 'blur', message: ' ' }],
        description: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      prodNoMaxLength: 7,
      prodNoDisabled: false,
      ilkList: [
        { id: 0, name: '新款' },
        { id: 1, name: '修改' },
        { id: 2, name: '翻单' }
      ],
      prodForm: {
        prod_attr: '',
        is_suit: '',
        requ_smpl_id: null,
        rqut_id: null,
        prod_no: null,
        prod_poststfix: null,
        prod_partno: null,
        prod_cust_no: null,
        cust_abbr: null,
        cust_id: null,
        prod_usd: null,
        cust_currency: 'USD',
        prod_dusd: null,
        prod_unit: null,
        prod_name: null,
        prod_ename: null,
        prod_spec: null,
        prod_espec: null,
        prod_desc: null,
        prod_hscode: null,
        prod_hsname: null,
        prod_ehsname: null,
        prod_hsinrate: null,
        prod_hsoutrate: null,
        prod_bar: null,
        prod_inbar: null,
        prod_outbar: null,
        prod_boxl: null,
        prod_boxw: null,
        prod_boxh: null,
        prod_vol: null,
        prod_qpb: null,
        prod_nweight: null,
        prod_qpc: null,
        prod_gweight: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        imge_commodity: null,
        imge_commodity_imge_url: '',
        imge_commodity_imge_urlO: '',
        imge_craft: null,
        imge_craft_imge_url: '',
        imge_craft_imge_urlO: '',
        supp_id: null,
        supp_ordr_total: null,
        prod_mainsup: '0',
        prod_price: null,
        prod_use: null,
        prod_part_list: [], //部件
        prod_mtrb_list: [], //材料
        prod_catnip_list: [], //子表 猫草明细
        prodList: [], //关联
        mtrl_no: '',
        mtrl_id: null,
        description: '',
        prodDetailed: {},
        prod_sales: '',
        cust_brand: ''
      },
      show: false,
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 1000,
        y: 1000
      },
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      stffList: [],
      loadingFlag: true,
      prodUnitList: [],
      custs: [],
      custCurrencyList: [],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      prodPartListCopy: [],
      prodMtrbListCopy: [],
      prodListCopy: [],
      isBussinessDept: false,
      isBussinessDept5: false
    };
  },
  created() {},
  async mounted() {
    this.isCopy = JSON.parse(UrlEncode.decode(this.$route.query?.key))?.isCopy ?? false;
    this.initData();
    this.prodCategoryOptions = Array.from(await getDataCenterByPermId({ id: 10032 }), item => {
      let obj = { value: item.param1, label: item.param1, param3: item.param3, param4: item.param4 };
      if (item.param2)
        obj.children = Array.from(item.param2.split(','), subItem => {
          return { value: subItem, label: subItem };
        });
      return obj;
    });
    let find = this.$refs.prodPartsList?.partTableProperties.find(x => x.label === '中文名称');
    if (find) find.subItem.required = this.prodForm.prod_attr === '1';
  },
  watch: {},
  methods: {
    keep4Decimal,
    keepLetter2AndNumber5(val) {
      if (val.length <= 2) {
        return val.replace(/[^A-Za-z]/g, '').toUpperCase();
      } else {
        let prefixStr = val.substring(0, 2);
        let subStr = val.substring(2, val.length);
        return prefixStr + subStr.replace(/[^0-9]/g, '');
      }
    },
    async initData() {
      this.getCusts();
      this.loadingFlag = false;
      this.getStffList();
      this.getprodInfo();
      this.getProdRankList();
      this.getCustCurrency();
      this.getProdTypeList();
      let yewu = await getDept('业务');
      let neimao = await getDept('内贸');
      let dianshang = await getDept('电商');
      let it = await getDept('IT部');
      let currentDept = this.$cookies.get('userInfo').dept_id;
      this.isBussinessDept = getArrayIds([...yewu, ...neimao, ...dianshang, ...it], 'value').includes(currentDept);
      this.isBussinessDept5 = (await getDept('业务五部', 'ids')).includes(currentDept);
      if (this.prodForm.prod_attr === '1') this.rules.prod_usd[0].required = true;
      else this.rules.prod_usd[0].required = this.prodForm.prod_attr !== '2';
    },
    getCusts() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custs = res.data.data.list;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    getProdTypeList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10030 })
        .then(res => {
          if (res.data.code === 0) {
            this.ProdCategory = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    changeFormProperties(arr, type) {
      if (type === '1') {
        arr[0].rules.required = true;
      } else arr[0].rules.required = type !== '2';
      return arr;
    },
    productTypeChange(val) {
      /*   let prodDetailed = this.$refs.dynamicForm.getFormModel();
      switch (val) {
        case '餐垫':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(diningMat), this.prodForm.prod_attr);
          break;
        case '木制品':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(woodwork), this.prodForm.prod_attr);
          break;
        case '食品':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(food), this.prodForm.prod_attr);
          break;
      }
      this.mainForm.formProperties.forEach(item => (item.model = prodDetailed[item.prop]));*/
    },
    category(val) {
      /*      let prodDetailed = this.$refs.dynamicForm.getFormModel();
      switch (val) {
        case '商检类':
          this.mainForm.formProperties = cloneDeep(inspFrom);
          if (this.prodForm.prodDetailed === null) {
            this.prodForm.prodDetailed = {};
            this.prodForm.prod_category = '商检类';
            this.prodForm.prodDetailed.product_type = '餐垫';
            this.productTypeChange('餐垫');
          } else {
            this.productTypeChange(this.prodForm.prodDetailed.product_type);
          }
          break;
        case '宠物包':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petBag), this.prodForm.prod_attr);
          break;
        case '毛绒玩具':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(plushToys), this.prodForm.prod_attr);
          break;
        case '织带':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(webbing), this.prodForm.prod_attr);
          break;
        case '汗衫布绳':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(sweatshirt), this.prodForm.prod_attr);
          break;
        case '宠物衣服':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petClothing), this.prodForm.prod_attr);
          break;
        case 'TPR':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(tpr), this.prodForm.prod_attr);
          break;
        case '宠物配饰（帽子、领结、围巾等）':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petAccessories), this.prodForm.prod_attr);
          break;
        case '尼龙':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(nylon), this.prodForm.prod_attr);
          break;
        case '橡胶':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(rubber), this.prodForm.prod_attr);
          break;
        case '宠物碗':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petBowl), this.prodForm.prod_attr);
          break;
        case '链条、项圈、胸背':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(chain), this.prodForm.prod_attr);
          break;
        case '宠物窝':
          this.mainForm.formProperties = this.changeFormProperties(cloneDeep(petNest), this.prodForm.prod_attr);
          break;
        case '其他':
          this.mainForm.formProperties = cloneDeep(other);
          break;
      }
      this.mainForm.formProperties.forEach(item => (item.model = prodDetailed[item.prop]));*/
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let partDynamicUTable = (await this.$refs.prodPartsList?.$refs.partDynamicUTable.fullValidateMsg()) || null;
          let mtrlDynamicUTable = (await this.$refs.ProdMasc?.$refs.mtrlDynamicUTable.fullValidateMsg()) || null;
          this.$refs[formName].validate((valid, object) => {
            if (valid && !partDynamicUTable && !mtrlDynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (partDynamicUTable) tempStr += `部件子表-${partDynamicUTable}`;
              if (mtrlDynamicUTable) tempStr += `材料子表-${mtrlDynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch();
    },
    // 保存
    saveInfo() {
      // if (this.prodForm.prod_attr === '1' || this.prodForm.prod_attr === '3') {
      //   if (!this.prodForm.imge_id && !this.prodForm.imge_commodity && !this.prodForm.imge_craft_imge_url) {
      //     return this.$message.warning('必须上传一张图片!');
      //   }
      // }
      if (this.prodForm.is_suit === '1') {
        this.prodForm.prodList = this.$refs.prodAssociated.tableData;
        this.prodForm.prodList = this.prodForm.prodList.concat(Array.from(this.$refs.prodAssociated.tableDataMap.values()));
        this.prodForm.prod_ids = Array.from(this.prodForm.prodList, ({ prod_id }) => prod_id).join(',');
      }
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let prodForm = JSON.parse(JSON.stringify(this.prodForm));
      prodForm.prod_category = prodForm.prod_category.toString();
      prodForm.prod_id = props.form_id;
      prodForm.personal = this.$cookies.get('userInfo').personal;
      if (prodForm.is_suit === '1' && this.prodListCopy) {
        this.prodListCopy = this.prodListCopy.filter(item => prodForm.prodList.every(item1 => item.prod_id !== item1.prod_id));
        for (let i in this.prodListCopy) {
          this.prodListCopy[i].destroy_flag = 1;
        }
        prodForm.prodList = prodForm.prodList.concat(this.prodListCopy);
      }
      this.prodMtrbListCopy = this.prodMtrbListCopy.filter(item => prodForm.prod_mtrb_list.every(item1 => item.prod_mtrb_id !== item1.prod_mtrb_id));
      for (let i in this.prodMtrbListCopy) {
        this.prodMtrbListCopy[i].destroy_flag = 1;
      }
      prodForm.prod_mtrb_list = prodForm.prod_mtrb_list?.concat(this.prodMtrbListCopy);
      let { prodPartsList } = this.$refs;
      if (prodPartsList) prodForm.prod_part_list = prodForm.prod_part_list?.concat(Array.from(prodPartsList.prod_part_list_map.values()));
      // let prod_detailed_id = null;
      // if (prodForm.prodDetailed) {
      //   if (prodForm.prodDetailed.prod_detailed_id) {
      //     prod_detailed_id = prodForm.prodDetailed.prod_detailed_id;
      //   }
      // }
      // let product_type = prodForm.prodDetailed.product_type;
      // let description = prodForm.prodDetailed.description;
      // let price_composition = prodForm.prodDetailed.price_composition;
      // prodForm.prodDetailed = this.$refs.dynamicForm.getFormModel();
      // prodForm.prodDetailed.prod_detailed_id = prod_detailed_id;
      // prodForm.prodDetailed.product_type = product_type;
      // prodForm.prodDetailed.description = description;
      // prodForm.prodDetailed.price_composition = price_composition;
      //加入猫草删除数据
      if (prodForm.prod_attr !== '2') {
        prodForm.prod_catnip_list.push(...this.$refs.ProdCatnipList.delList);
      }
      post(prodAPI.editProd, prodForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            this.$message.error(res.data.msg);
            this.initData();
          }
        })
        .catch(err => console.error(err));
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //导入文本信息
    inputClau(row) {
      this.prodForm.description = row;
      this.dialogVisible4 = false;
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('prodForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 打开我司货号导入框
    importPordNo() {
      if (this.prodForm.prod_attr === '1') {
        this.dialogVisible1 = true;
      } else if (this.prodForm.prod_attr === '2') {
        this.dialogVisible2 = true;
      }
    },
    // 导入我司货号传值
    async changePordNo(val) {
      let importObj = JSON.parse(JSON.stringify(val));
      this.prodForm.prod_no = importObj.prod_no;
      this.prodForm.prod_name = importObj.prod_name;
      // this.prodForm.prod_poststfix = importObj.prod_poststfix;
      // if (this.prodForm.prod_attr === '1' || this.prodForm.prod_attr === '3') {
      //   this.prodForm.prod_no = importObj.smpl_no;
      //   await prodAPI.getImageUrlByProdNo({ smpl_no: importObj.smpl_no }).then(({ data }) => {
      //     importObj.imge_craft_imge_url = data?.imge_url ?? null;
      //   });
      //   this.prodForm.requ_smpl_id = importObj.requ_smpl_id;
      //   this.prodForm.prod_cust_no = importObj.prod_cust_no;
      //   this.prodForm.cust_abbr = importObj.cust_abbr;
      //   this.prodForm.cust_id = importObj.cust_id;
      //   this.prodForm.rqut_id = 0;
      //   this.prodForm.prod_name = importObj.prod_name;
      //   this.prodForm.prod_spec = importObj.prod_spec;
      //   if (importObj.imge_craft_imge_url) {
      //     this.prodForm.imge_craft_imge_url = importObj.imge_craft_imge_url;
      //     this.prodForm.imge_craft_imge_urlO = importObj.imge_craft_imge_url;
      //     let urlImg = importObj.imge_craft_imge_url;
      //     let that = this;
      //     let image = new Image();
      //     image.crossOrigin = '';
      //     image.src = urlImg;
      //     image.onload = function () {
      //       let base64 = imageToBase64(image); //图片转base64
      //       let file = base64ToFile(base64, 'file'); //base64转File
      //       setTimeout(() => {
      //         that.$refs.craftImge.handleStart(file); // 将粘贴过来的图片加入预上传队列
      //         that.$refs.craftImge.submit(); // 提交图片上传队列
      //       }, 300);
      //     };
      //   }
      // } else if (this.prodForm.prod_attr === '2') {
      //   this.prodForm.prod_no = importObj.prod_no;
      //   this.prodForm.rqut_id = importObj.rqut_id;
      //   this.prodForm.requ_smpl_id = 0;
      //   this.prodForm.prod_name = importObj.prod_name;
      //   this.prodForm.prod_spec = importObj.prod_spec;
      //   if (val.rqut_bidd_list.length > 0) {
      //     for (let i = 0; i < val.rqut_bidd_list.length; i++) {
      //       if (val.rqut_bidd_list[i].rqut_bidd_win === 1) {
      //         this.prodForm.supp_id = val.rqut_bidd_list[i].supp_id;
      //         this.prodForm.prod_price = this.helper.haveFour(val.rqut_bidd_list[i].rqut_bidd_price);
      //       }
      //     }
      //   }
      // }
      this.prodNoDisabled = true;
      this.prodNoMaxLength = 30;
      this.dialogVisible2 = false;
      this.dialogVisible1 = false;
    },
    imgCopys(url) {
      let that = this;
      var image = new Image();
      image.crossOrigin = '';
      image.src = url;
      image.onload = function () {
        let base64 = imageToBase64(image); //图片转base64

        let file = base64ToFile(base64, 'file'); //base64转File
        // return file;
        setTimeout(() => {
          that.$refs.uploadMutiple.handleStart(file); // 将粘贴过来的图片加入预上传队列
          that.$refs.uploadMutiple.submit(); // 提交图片上传队列
        }, 300);
      };
    },
    // 属性变化
    prodAttrChange() {
      this.prodForm.prod_no = null;
      this.prodForm.prod_poststfix = null;
      this.prodForm.prod_cust_no = null;
      this.prodForm.cust_abbr = null;
      this.prodForm.prod_unit = null;
      if (this.prodForm.prod_attr === '1') {
        this.prodForm.supp_id = null;
        this.prodForm.supp_ordr_total = null;
        this.prodForm.prod_mainsup = '0';
        this.prodForm.prod_price = null;
        this.prodForm.prod_use = null;
        this.rules.prod_usd[0].required = true;
      } else if (this.prodForm.prod_attr === '2') {
        this.prodForm.prod_part_list = [];
        this.rules.prod_usd[0].required = false;
      } else {
        this.prodForm.is_suit = '2';
        this.rules.prod_usd[0].required = true;
      }
      // this.category('其他');
    },
    // 客户简称传值
    changeCustAbbr(val) {
      this.prodForm.cust_abbr = val.cust_abbr;
      this.prodForm.cust_id = val.cust_id;
      this.dialogVisible3 = false;
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取稿件等级
    getProdRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.prodUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 成功上传
    handleAvatarSuccess(res, index) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        switch (index) {
          case 1:
            this.prodForm.imge_id = res.data.imge_id;
            this.prodForm.imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
            this.prodForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            break;
          case 2:
            this.prodForm.imge_commodity = res.data.imge_id;
            this.prodForm.imge_commodity_imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
            this.prodForm.imge_commodity_imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            break;
          case 3:
            // this.prodForm.imge_craft = null;
            // this.prodForm.imge_craft_imge_url = urlEng + '_m.jpg';
            // this.prodForm.imge_craft_imge_urlO = urlEng + '_l.jpg';
            break;
        }
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false;
      }
    },
    handlePaste(event, index) {
      if (!this.isShow) {
        const items = (event.clipboardData || window.clipboardData).items;
        let file = null;
        if (!items || items.length === 0) {
          this.$message.error('当前浏览器不支持本地');
          return;
        }
        // 搜索剪切板items
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
            break;
          }
        }
        if (!file) {
          this.$message.error('粘贴内容非图片');
          return;
        }
        this.$refs[`uploadMutiple${index}`].handleStart(file); // 将粘贴过来的图片加入预上传队列
        this.$refs[`uploadMutiple${index}`].submit(); // 提交图片上传队列
      } else {
        this.$message.warning('请先点击编辑');
      }
    },
    clearImage(index) {
      switch (index) {
        case 1:
          this.prodForm.imge_id = '';
          this.prodForm.imge_url = '';
          this.prodForm.imge_urlO = '';
          break;
        case 2:
          this.prodForm.imge_commodity = '';
          this.prodForm.imge_commodity_imge_url = '';
          this.prodForm.imge_commodity_imge_urlO = '';
          break;
        case 3:
          break;
      }
    },
    // 获取表单信息
    getprodInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.prodForm.prod_id = props.form_id;
      this.prodForm.prod_attr = props.prod_attr;
      if (this.prodForm.prod_attr === '2') {
        this.ifProdAttr = false;
      }
      get(prodAPI.getProdById, { prod_id: this.prodForm.prod_id, prod_attr: this.prodForm.prod_attr }).then(res => {
        if (res.data.code === 0) {
          res.data.data.form.is_suit += '';
          res.data.data.form.prod_attr += '';
          this.prodForm = res.data.data.form;
          this.btn = res.data.data.btn;
          if (Number(this.prodForm.is_suit) === 1) {
            setTimeout(() => {
              this.$refs.prodAssociated.$data.tableData = this.prodForm.prodList;
            }, 500);
          }
          this.prodForm.prod_category = this.prodForm.prod_category.split(',');
          this.prodForm.prod_part_list?.forEach(item => (item['disabled'] = true));
          this.prodPartListCopy = JSON.parse(JSON.stringify(this.prodForm.prod_part_list));
          this.prodMtrbListCopy = JSON.parse(JSON.stringify(this.prodForm.prod_mtrb_list));
          this.prodListCopy = JSON.parse(JSON.stringify(this.prodForm.prodList));
          this.stffForm.stff_name = this.prodForm.stff_name;
          this.stffForm.dept_name = this.prodForm.dept_name;
          this.stffForm.dept_team_name = this.prodForm.dept_team_name;
          this.stffForm.user_id = this.prodForm.user_id;
          this.stffForm.dept_id = this.prodForm.dept_id;
          this.stffForm.stff_id = this.prodForm.stff_id;
          this.prodForm.prod_inrate = this.helper.haveFour(this.prodForm.prod_inrate);
          this.prodForm.prod_price = this.helper.haveFour(this.prodForm.prod_price);
          let imge_url = JSON.parse(JSON.stringify(this.prodForm.imge_url));
          let imge_commodity_url = JSON.parse(JSON.stringify(this.prodForm.imge_commodity_url));
          let imge_craft_url = JSON.parse(JSON.stringify(this.prodForm.imge_craft_url));
          this.prodForm.prodDetailed = res.data.data.form.prodDetailed;
          if (this.prodForm.imge_id) {
            this.dataBody.imge_id = this.prodForm.imge_id;
          } else {
            this.dataBody.imge_id = 0;
          }
          let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
          this.prodForm.imge_url = this.helper.picUrl(imge_url, 'm', updateTime);
          this.prodForm.imge_urlO = this.helper.picUrl(imge_url, 'l', updateTime);
          this.prodForm.imge_commodity_imge_url = this.helper.picUrl(imge_commodity_url, 'm', updateTime);
          this.prodForm.imge_commodity_imge_urlO = this.helper.picUrl(imge_commodity_url, 'l', updateTime);
          this.prodForm.imge_craft_imge_url = imge_craft_url;
          this.prodForm.imge_craft_imge_urlO = imge_craft_url;
          if (this.show) {
            this.prodForm.imge_id = this.imgForm.imge_id;
            this.prodForm.imge_url = this.imgForm.imge_url;
            this.prodForm.imge_urlO = this.imgForm.imge_urlO;
            this.show = false;
          }
          if (this.prodForm['prod_sales'] === 1) this.rules.prod_hscode[0].required = false;
          this.imgForm.imge_url = JSON.parse(JSON.stringify(this.prodForm.imge_url));
          this.imgForm.imge_id = JSON.parse(JSON.stringify(this.prodForm.imge_id));
          this.imgForm.imge_urlO = JSON.parse(JSON.stringify(this.prodForm.imge_urlO));
          this.prodForm.prod_out += '';
          this.prodForm.prod_inspection += '';
          this.calcdigit();
          if (this.$route.params.syncData) {
            this.prodForm.prod_unit = null;
            this.prodForm = Object.assign({}, this.prodForm, this.$route.params.syncData);
            this.prodForm.prod_mainsup += '';
            this.prodForm.prod_part_list.forEach(item => {
              this.$set(item, 'supplementInfo', { prod_hscode: '', prod_spec: '' });
              item.supp_abbr = parseInt(item.supp_abbr);
            });
            delete this.$route.params.syncData;
          }
          setTimeout(() => {
            this.loadingFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    calcdigit() {
      if (this.prodForm.supp_id === 0) {
        this.prodForm.supp_id = null;
      }
      if (this.prodForm.prod_qpb === 0) {
        this.prodForm.prod_qpb = null;
      }
      if (this.prodForm.prod_qpc === 0) {
        this.prodForm.prod_qpc = null;
      }
      if (this.prodForm.prod_usd) {
        this.prodForm.prod_usd = this.helper.haveFour(this.prodForm.prod_usd);
      } else {
        this.prodForm.prod_usd = null;
      }
      if (this.prodForm.prod_dusd) {
        this.prodForm.prod_dusd = this.helper.haveFour(this.prodForm.prod_dusd);
      } else {
        this.prodForm.prod_dusd = null;
      }
      if (this.prodForm.prod_boxl) {
        this.prodForm.prod_boxl = this.helper.reservedDigits(this.prodForm.prod_boxl);
      } else {
        this.prodForm.prod_boxl = null;
      }
      if (this.prodForm.prod_boxw) {
        this.prodForm.prod_boxw = this.helper.reservedDigits(this.prodForm.prod_boxw);
      } else {
        this.prodForm.prod_boxw = null;
      }
      if (this.prodForm.prod_boxh) {
        this.prodForm.prod_boxh = this.helper.reservedDigits(this.prodForm.prod_boxh);
      } else {
        this.prodForm.prod_boxh = null;
      }
      if (this.prodForm.prod_gweight) {
        this.prodForm.prod_gweight = this.helper.reservedDigits(this.prodForm.prod_gweight);
      } else {
        this.prodForm.prod_gweight = null;
      }
      if (this.prodForm.prod_nweight) {
        this.prodForm.prod_nweight = this.helper.reservedDigits(this.prodForm.prod_nweight);
      } else {
        this.prodForm.prod_nweight = null;
      }
      if (this.prodForm.prod_hsinrate) {
        this.prodForm.prod_hsinrate = this.helper.haveFour(this.prodForm.prod_hsinrate);
      } else {
        this.prodForm.prod_hsinrate = null;
      }
      if (this.prodForm.prod_hsoutrate) {
        this.prodForm.prod_hsoutrate = this.helper.haveFour(this.prodForm.prod_hsoutrate);
      } else {
        this.prodForm.prod_hsoutrate = null;
      }
      if (this.prodForm.prod_attr === '1') {
        this.prodForm.prod_part_list?.forEach(item => {
          if (item.prod_price) {
            item.prod_price = this.helper.haveFour(item.prod_price);
          } else {
            item.prod_price = null;
          }
        });
      } else {
        this.prodForm.prod_mainsup = this.prodForm.prod_mainsup + '';
      }
      this.prodForm.prod_mtrb_list?.forEach(item => {
        if (item.prod_mtrb_price) {
          item.prod_mtrb_price = this.helper.haveFour(item.prod_mtrb_price);
        } else {
          item.prod_mtrb_price = null;
        }
      });
    },
    //获取销售经办人
    getStffList() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
          }
        })
        .catch(() => {});
    },
    generateFPPC() {
      prodAPI.getProdToPodr({ part_id: this.prodForm.part_id }).then(({ data }) => {
        this.$router.push({
          name: 'podrAdd',
          query: { perm_id: 127, UUID: crypto.randomUUID() },
          params: { syncData: data }
        });
      });
    },
    cascaderChange(val) {
      if (this.prodForm.prod_attr === '1') return;
      let { param3, param4 } = this.prodCategoryOptions.find(x => x.label === val[0]);
      let param3Arr = param3?.split('/*/') || [];
      let param4Arr = param4?.split('/*/') || [];
      let result = '';
      param3Arr.forEach((item, index) => {
        if (param4Arr[index]) result += `${item}${this.prodForm[param4Arr[index]] || ''}\n`;
        else result += item + '\n';
      });
      this.prodForm.description += result;
    }
  },
  computed: {
    custBrandList() {
      if (!this.prodForm.cust_id) {
        this.prodForm.cust_brand = '';
        return [];
      }
      return this.custs.find(x => x.cust_id === this.prodForm.cust_id)?.cust_brand_list || [];
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 200px;
  width: 200px;
}

::v-deep .el-upload-dragger {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vd_dis {
  display: flex;
  align-items: center;
}

.vd_flex_right {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

::v-deep .el-form-item__label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep .el-form-item__label:hover {
  height: auto;
  word-break: break-all;
  white-space: pre-wrap;
  text-decoration: none;
}

::v-deep .content {
  height: 82.5vh !important;
}
</style>
